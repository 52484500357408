import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import BrowserRouter and Routes
import { getAuth, useAuthState } from 'firebase/auth';
import { getDatabase, ref, onValue, set, push, update, onDisconnect, serverTimestamp, orderByChild, limitToLast, query } from 'firebase/database';
import YoutubeInput from './YoutubeInput';

const auth = getAuth();
const database = getDatabase();

function User() {
  const { username } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [isFollowing, setIsFollowing] = useState(false);
  
  useEffect(() => {
    const userRef = query(ref(database, 'users'), orderByChild('count'));

    onValue(userRef, (snapshot) => {
      console.log(snapshot)
      snapshot.forEach((childSnapshot) => {
        setIsFollowing(childSnapshot.value);
      });
    }, {
      onlyOnce: true
    });
  }, []);

  console.log(user);

  const follow = () => {
    setIsFollowing(!isFollowing);
  };

  return (
    <header className="App-header" style={{height: '86vh', margin: '7vh'}}>
      <YoutubeInput />
    </header>
  );
}

export default User;
