import React, { useState, useEffect, useRef } from 'react';
import recommendLogo from '../recommend.svg';
import recommendedLogo from '../recommended.svg';
import { useParams, useNavigate } from 'react-router-dom'; // Import BrowserRouter and Routes
import { getDatabase, ref, onValue, set, push, update, onDisconnect, serverTimestamp } from 'firebase/database';
import { getAuth, useAuthState } from 'firebase/auth';

const auth = getAuth();
const database = getDatabase();

const createYoutubeLink = (videoId) => {
  if (!videoId) return "";
  return `https://www.youtube.com/watch?v=${videoId}`;
};

function convertToEmbedURL(url, apiKey) {
  // Encode the URL and API key
  const encodedUrl = encodeURIComponent(url);
  const encodedApiKey = encodeURIComponent(apiKey);
  
  // Construct the embed URL
  const embedURL = `https://iframe.ly/api/oembed?url=${encodedUrl}&api_key=${encodedApiKey}`;
  
  return embedURL;
}

function YoutubeInput({videoId}) {
  //const { videoId } = useParams();
  //if (!videoId) {videoId} = useParams();
  const navigate = useNavigate();

  let createdYoutubeLink = createYoutubeLink(videoId);
  const [youtubeLink, setYoutubeLink] = useState(createdYoutubeLink);
  const [user, setUser] = useState({});
  const [isRecommended, setIsRecommended] = useState(false);
  const [count, setCount] = useState(0);

  console.log(videoId, youtubeLink);
  console.log(user);

  const handleYoutubeLinkChange = (event) => {
    let url = convertToEmbedURL(event.target.value, 'ae1d87e2af678204750568');
    console.log(url);
    setYoutubeLink(event.target.value);
    const videoId = extractVideoId(event.target.value);

    navigate(`/video/${videoId}`); // Redirect to the /video/:videoId route
    //handleLoadVideo();
  };

  const handleLoadVideo = () => {
    const videoId = extractVideoId(youtubeLink);
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0`;
      document.getElementById("youtube-iframe").src = embedUrl;
    }
  };

  const extractVideoId = (link) => {
    // Regular expression to match a full YouTube URL
    const fullUrlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*[?&]v=))([\w-]{11})/;
    
    // Match the video ID using the regex
    const fullUrlMatch = link.match(fullUrlRegex);
    
    // If a match is found for a full URL, return the video ID (group 1 from the regex match)
    if (fullUrlMatch) {
      return fullUrlMatch[1];
    }
  
    // If no match is found for a full URL, assume the input is a direct video ID
    return link;
  };
  

  const toggleLogo = () => {
    const myRef = ref(database, 'users/mohit');

    setIsRecommended(!isRecommended);
  };

  return (
    <header key={videoId} className="App-header" style={{height: '86vh', margin: '7vh'}}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className="sil" style={{color: '#202124', fontSize: 35}}>Recommenderz</p>
        <input
          className="link-input"
          style={{ fontFamily: 'monospace', height: '5vh', width: '38vh', fontWeight: 200, border: '1px solid #ccc', borderRadius: '5px',
          '::selection': { color: 'black' } /* Apply black color to selected text */
        }}
          type="text"
          value={youtubeLink}
          onChange={handleYoutubeLinkChange}
          placeholder="Enter YouTube link"
        />
      </div>
  </header>
  );
}

export default YoutubeInput;
