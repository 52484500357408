import React from 'react';

function PrivacyPolicy() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
<h1>Privacy Policy</h1>
<p>This Privacy Policy outlines how your personal information is collected, utilized, and disclosed when you interact with recommenderz.com, registered under the business entity - Rcommndr, in India. (referred to as the "Site").</p>
<h2>Information Collection:</h2>
<p>When you visit our platform, we automatically gather a variety of information to enhance your experience and improve our services. This includes technical details about your device, such as your web browser, IP address, time zone, and the types of cookies installed. Additionally, as you navigate through our platform, we collect data about the specific pages or products you view, as well as the sources that directed you to our site. We also analyze how you interact with our platform to better understand user behavior and preferences.</p>
<h2>Use of Personal Information:</h2>
<p>We utilize the personal information we collect to provide and improve our services, personalize your experience, and communicate with you. This may involve customizing the content and advertisements you see, processing transactions, and providing customer support. We may also use your information to send you important updates, promotional offers, and other communications related to our services.</p>
<h2>Sharing Personal Information:</h2>
<p>We may share your personal information with trusted third-party service providers to assist us in operating our platform and delivering our services. For example, we may use third-party payment processors to facilitate transactions, or analytics providers to help us analyze and improve our platform. These service providers are contractually obligated to only use your information as necessary to provide their services to us and are prohibited from using it for any other purpose.</p>
<p>Additionally, we may disclose your personal information if required by law or if we believe that such disclosure is necessary to protect our rights, comply with a legal obligation, or protect the safety of our users or others.</p>
<h2>Security:</h2>
<p>We take the security of your personal information seriously and have implemented appropriate technical and organizational measures to protect it against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>

    </div>
  );
}

export default PrivacyPolicy;
