// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage } from 'firebase/messaging/sw';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCj35ybMRJpya-BxdE1q_nDruH6uFSo9tM",
  authDomain: "recommenderz-1.firebaseapp.com",
  databaseURL: "https://recommenderz-1-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "recommenderz-1",
  storageBucket: "recommenderz-1.appspot.com",
  messagingSenderId: "43493350864",
  appId: "1:43493350864:web:96fc0ed80ce345ea0a6dd5",
  measurementId: "G-HKLLRRYZRE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const messaging = getMessaging(app);