import React from 'react';

function Pricing() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>Pricing</h1>
      <p>Our pricing model is designed to be simple and transparent. As a social-commerce platform, we take a 10% commission on all successful transactions processed through our platform. Creators are free to set their own prices for their products, allowing them full control over their earnings.</p>
      <h2>Transaction Fees:</h2>
      <p>We charge a flat 10% commission on the total transaction amount for each successful sale made on our platform. This commission covers the cost of payment processing, platform maintenance, and other operational expenses.</p>
      <h2>Creator Pricing:</h2>
      <p>Creators have the flexibility to set their own prices for their products. They can choose the price that best reflects the value of their work and meets the demands of their target audience. Our platform empowers creators to maximize their earnings by providing them with full control over their pricing strategy.</p>
      <h2>Payment Processing:</h2>
      <p>We offer secure payment processing solutions to ensure a seamless and reliable transaction experience for both creators and customers. Payments are processed securely through our platform, and creators receive their earnings directly to their designated accounts.</p>
      <h2>Get Started:</h2>
      <p>If you're ready to join our social-commerce platform as a creator or explore our marketplace as a customer, sign up today to get started. For more information or assistance, feel free to contact our support team.</p>
    </div>
  );
}

export default Pricing;
