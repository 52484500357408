import React, { useState, useEffect, useRef } from 'react';
import recommendLogo from '../recommend.svg';
import recommendedLogo from '../recommended.svg';
import { useParams, useNavigate } from 'react-router-dom'; // Import BrowserRouter and Routes
import { getDatabase, ref, onValue, set, push, update, onDisconnect, serverTimestamp } from 'firebase/database';
import { getAuth, useAuthState } from 'firebase/auth';
import {Button, Modal} from 'antd';

const auth = getAuth();
const database = getDatabase();

const createYoutubeLink = (videoId) => {
  if (!videoId) return "";
  return `https://www.youtube.com/watch?v=${videoId}`;
};

function Youtube({videoId}) {
  //const { videoId } = useParams();
  let v = useParams();
  if (!videoId) {
    videoId = v.videoId;
  }

  const navigate = useNavigate();

  let createdYoutubeLink = createYoutubeLink(videoId);
  const [youtubeLink, setYoutubeLink] = useState(createdYoutubeLink);
  const [user, setUser] = useState({});
  const [isRecommended, setIsRecommended] = useState(false);
  const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  console.log(videoId, youtubeLink);
  console.log(user);

  const handleYoutubeLinkChange = (event) => {
    setYoutubeLink(event.target.value);
    const videoId = extractVideoId(event.target.value);

    navigate(`/video/${videoId}`); // Redirect to the /video/:videoId route
    //handleLoadVideo();
  };

  const handleLoadVideo = () => {
    const videoId = extractVideoId(youtubeLink);
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0`;
      document.getElementById("youtube-iframe").src = embedUrl;
    }
  };

  const extractVideoId = (link) => {
    // Regular expression to match a full YouTube URL
    const fullUrlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*[?&]v=))([\w-]{11})/;
    
    // Match the video ID using the regex
    const fullUrlMatch = link.match(fullUrlRegex);
    
    // If a match is found for a full URL, return the video ID (group 1 from the regex match)
    if (fullUrlMatch) {
      return fullUrlMatch[1];
    }
  
    // If no match is found for a full URL, assume the input is a direct video ID
    return link;
  };
  

  const toggleLogo = () => {
    const myRef = ref(database, 'users/mohit');

    setIsRecommended(!isRecommended);
  };

  console.log(youtubeLink)
  return (
    <header key={videoId} className="App-header" style={{height: '86vh', margin: '7vh'}}>
    {!youtubeLink && false ? 
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className="sil" style={{color: '#202124', fontSize: 35}}>Recommenderz</p>
        <input
          className="link-input"
          style={{ fontFamily: 'monospace', height: '5vh', width: '38vh', fontWeight: 200, border: '1px solid #ccc', borderRadius: '5px',
          '::selection': { color: 'black' } /* Apply black color to selected text */
        }}
          type="text"
          value={youtubeLink}
          onChange={handleYoutubeLinkChange}
          placeholder="Enter YouTube link"
        />
      </div>
      : videoId ? 
      <div>
        <iframe
          style={{height: '51vh', width: '52vw'}}
          id="youtube-iframe"
          src={"https://www.youtube.com/embed/" + videoId + "?rel=0&showinfo=0&autoplay=1"}
          allowFullScreen
          title="Embedded YouTube Video"
        ></iframe>
        <input style={{width: '100px', height: '5vh', position: 'absolute', paddingLeft: '2vh', marginLeft: 20, marginTop: 50, cursor: 'pointer'}} type="number" placeholder="INR 1-100000"></input>
        <Button type="primary" onClick={showModal} style={{background: 'black', color: 'white', width: '120px', height: '5vh', position: 'absolute', paddingLeft: '2vh', marginTop: 100, cursor: 'pointer', marginLeft: 20}}>Pay</Button>
        <img style={{ width: '5vh', height: '5vh', position: 'absolute', paddingLeft: '2vh', marginLeft: 20}} src={isRecommended ? recommendedLogo : recommendLogo} className="App-logo" alt="logo" onClick={() => {toggleLogo(); handleLoadVideo();}} />
        <Modal title="Pay" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>This video's owner hasn't linked his Google account to accept payments yet.</p>
        </Modal>
      </div> : null
    }
  </header>
  );
}

export default Youtube;
