import React from 'react';

function RefundPolicy() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>Refund Policy</h1>
      <p>This Refund Policy outlines our procedures regarding refunds for purchases made on Recommenderz (referred to as the "Site").</p>
      <h2>Eligibility for Refunds:</h2>
      <p>We strive to provide high-quality products and services to our customers. If you are dissatisfied with your purchase for any reason, please contact us within 7 working days of the original purchase date to request a refund.</p>
      <h2>Refund Process:</h2>
      <p>Once your refund request is received and approved, we will initiate a refund to your original method of payment. Please allow 7 working days for the refund to be processed and reflected in your account.</p>
      <h2>Exceptions:</h2>
      <p>Some products or services may not be eligible for refunds. These exceptions will be clearly outlined at the time of purchase, and refunds will not be granted for products or services excluded from our refund policy.</p>
      <h2>Contact Us:</h2>
      <p>If you have any questions or concerns about our refund policy or need assistance with a refund request, please contact us at contact@recommenderz.com. We are committed to ensuring customer satisfaction and will do our best to address your inquiries promptly.</p>
    </div>
  );
}

export default RefundPolicy;
