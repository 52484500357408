import React from 'react';

function TermsAndConditions() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>Terms and Conditions</h1>
      <p>These Terms and Conditions govern your use of Recommenderz (referred to as the "Site"). By accessing or using the Site, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these Terms and Conditions or our Privacy Policy, please do not use the Site.</p>
      <h2>Intellectual Property:</h2>
      <p>All content included on the Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of [Your Company Name] or its content suppliers and is protected by international copyright laws.</p>
      <h2>Use of the Site:</h2>
      <p>You may use the Site only for lawful purposes and in accordance with these Terms and Conditions. You agree not to use the Site in any way that violates any applicable federal, state, local, or international law or regulation.</p>
      <h2>Limitation of Liability:</h2>
      <p>In no event shall Recommenderz, its directors, officers, employees, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use or inability to use the Site.</p>
      <h2>Changes to Terms and Conditions:</h2>
      <p>We reserve the right to revise these Terms and Conditions at any time without prior notice. By continuing to access or use the Site after any revisions become effective, you agree to be bound by the revised Terms and Conditions. If you do not agree to the new Terms and Conditions, please stop using the Site.</p>
      <h2>Contact Us:</h2>
      <p>If you have any questions or concerns about these Terms and Conditions, please contact us at contact@recommenderz.com.</p>
    </div>
  );
}

export default TermsAndConditions;
