import React, { useState, useRef, useEffect } from 'react';

function WebRTC() {
  const [showVideo, setShowVideo] = useState(true);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);

  const toggleVideo = () => {
    if (showVideo) {
      stopStream();
    } else {
      startStream();
    }
    setShowVideo(prevShowVideo => !prevShowVideo);
  };

  useEffect(() => {
    startStream();
    return () => {
      stopStream();
    };
  }, []);

  const startStream = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({   video: {
        width: { ideal: 1280, max: 4096 },   // Maximum width of the video stream
        height: { ideal: 720, max: 2160 },   // Maximum height of the video stream
        frameRate: { ideal: 60, max: 60 }     // Maximum frame rate of the video stream
      }, });
      setStream(mediaStream);
      videoRef.current.srcObject = mediaStream;
    } catch (error) {
      console.error('Error accessing media devices: ', error);
    }
  };

  const stopStream = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setStream(null);
      videoRef.current.srcObject = null;
    }
  };

  return (
    <header className="App-header" style={{height: '86vh', margin: '10vh'}}>
      {showVideo && <video style={{width: '100vh'}} ref={videoRef} autoPlay muted playsInline />}
      <button onClick={toggleVideo}>{showVideo ? 'Hide Video' : 'Show Video'}</button>
    </header>
  );
}

export default WebRTC;
