
import React from 'react';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';

const { Search: AntSearch } = Input; // Rename the imported Search component
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);
const onSearch = (value, _e, info) => console.log(info?.source, value);
const SearchC = () => (
  <Space direction="vertical">
    <AntSearch
      placeholder="Search..."
      allowClear
      onSearch={onSearch}
      style={{
        width: 200,
        marginTop: 10
      }}
    />
  </Space>
);
export default SearchC;
